<template>
    <div v-show="!loading">
        <formulateForm
            v-slot="{ isLoading, hasErrors }"
            v-model="formData"
            name="actionsForm"
            @submit="handleSubmit"
        >
            <b-card class="mt-2">
                <div class="mb-2">
                    <span class="section-header">
                        <feather-icon icon="UserIcon" size="14"></feather-icon>
                        {{ $t("modules.people.profile") }}
                    </span>
                </div>

                <b-row class="mt-2">
                    <b-col cols="12" md="4">
                        <formulateInput
                            :label="$tc('modules.actions.description', 1)"
                            name="title"
                            :placeholder="$t('modules.actions.type')"
                            type="text"
                            validation="required|max:255,length"
                        />
                    </b-col>
                    <b-col cols="12" md="4">
                        <formulateInput
                            :label="$t('modules.actions.type')"
                            name="actionCategory"
                            :placeholder="
                                $t('common.crud.select') +
                                ' ' +
                                $t(
                                    'modules.actions.actionCategory'
                                ).toLowerCase()
                            "
                            type="vue-select"
                            validation="optional"
                            :options="
                                actionsCategoriesOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="formData.actionCategory = $event"
                        />
                    </b-col>
                    <b-col cols="12" md="2">
                        <formulate-input
                            v-model="formData.initialPeriod"
                            :label="$t('modules.actions.initialPeriod')"
                            name="initialPeriod"
                            type="custom-date"
                            :placeholder="$t('customFormats.date')"
                            validation="optional|date:YYYY-MM-DD"
                            @update:date="formData.initialPeriod = $event"
                        >
                        </formulate-input>
                    </b-col>
                    <b-col cols="12" md="2">
                        <formulate-input
                            v-model="formData.finalPeriod"
                            :label="$t('modules.actions.finalPeriod')"
                            name="finalPeriod"
                            type="custom-date"
                            validation="optional|date:YYYY-MM-DD"
                            :placeholder="$t('customFormats.date')"
                            @update:date="formData.finalPeriod = $event"
                        >
                        </formulate-input>
                    </b-col>
                </b-row>

                <b-row class="mt-2">
                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.actions.budgetAccount')"
                            name="budgetAccount"
                            placeholder="0"
                            type="text"
                            validation="optional|max:255,length"
                        />
                    </b-col>
                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.actions.budget')"
                            name="budget"
                            :placeholder="$n(0.0, 'currency')"
                            type="text"
                            validation="optional|number"
                        />
                    </b-col>
                    <b-col cols="12" md="3">
                        <select-user
                            name="responsible"
                            :placeholder="
                                $t('common.crud.select') +
                                ' ' +
                                $tc(
                                    'modules.people.description',
                                    1
                                ).toLowerCase()
                            "
                            :label="$t('modules.actions.responsible')"
                            validation="optional"
                            :options="responsibleOptions"
                            @update:options="responsibleOptions = $event"
                            @update:input="formData.responsible = $event"
                        ></select-user>
                    </b-col>
                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.actions.goalCategory')"
                            name="goalCategory"
                            :placeholder="
                                $t('common.crud.select') +
                                ' ' +
                                $t('modules.actions.goalCategory').toLowerCase()
                            "
                            type="vue-select"
                            validation="optional"
                            :options="
                                goalCategoriesOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="formData.goalCategory = $event"
                        />
                    </b-col>
                </b-row>

                <b-row class="mt-2">
                    <b-col cols="12" md="6">
                        <formulateInput
                            :label="$t('modules.actions.objective')"
                            name="objective"
                            :placeholder="
                                $t('common.crud.informAttribute', {
                                    attribute: $t(
                                        'modules.actions.actionObjective'
                                    ).toLowerCase(),
                                })
                            "
                            type="textarea"
                            validation="optional|max:255,length"
                        />
                    </b-col>
                    <b-col cols="12" md="6">
                        <formulateInput
                            :label="$t('common.crud.description')"
                            name="description"
                            :placeholder="
                                $t('common.crud.informAttribute', {
                                    attribute: $tc(
                                        'modules.actions.actionDescription',
                                        1
                                    ).toLowerCase(),
                                })
                            "
                            type="textarea"
                            validation="optional|max:255,length"
                        />
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="d-flex justify-content-center">
                        <formulateErrors />
                    </b-col>
                </b-row>

                <b-row
                    class="justify-content-center align-self-center mt-2 ml-0"
                >
                    <formulateInput
                        :disabled="hasErrors || isLoading"
                        :label="
                            isLoading
                                ? $t('common.crud.updating')
                                : $t('common.crud.update')
                        "
                        inputClass="btn btn-primary"
                        type="submit"
                        class="pr-2"
                    />
                    <formulateInput
                        :label="$t('common.crud.close')"
                        inputClass="btn btn-danger"
                        type="button"
                        class="pl-2"
                        @click="$emit('closeEditForm')"
                    />
                </b-row>
            </b-card>
        </formulateForm>
    </div>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue"
import Api from "@/services/api"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import SelectUser from "@/views/components/utilis-spa/vue-select-async/SelectUser.vue"
import _ from "lodash"
import "@sum.cumo/vue-datepicker/dist/Datepicker.css"

export default {
    components: {
        BRow,
        BCol,
        SelectUser,
        BCard,
    },
    props: {
        actionData: {
            required: true,
            type: Object,
        },
        actionsCategoriesOptions: {
            required: true,
            type: Array,
        },
        goalCategoriesOptions: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            formData: {
                title: "",
                initialPeriod: "",
                finalPeriod: "",
                budgetAccount: "",
                budget: "",
                objective: "",
                description: "",
                actionCategory: "",
                goalCategory: "",
                responsible: "",
            },
            responsibleOptions: [],
            updatedaction: null,
            loading: true,
        }
    },
    mounted() {
        this.setInitialValues(this.actionData)
    },
    methods: {
        setInitialValues(data) {
            const responsible = _.get(this.actionData, "responsible")
            if (responsible)
                this.responsibleOptions = [
                    { label: responsible.name, value: responsible.id },
                ]
            this.updatedaction = data
            this.formData.id = data.id
            this.formData.title = data.title
            this.formData.initialPeriod = data.initialPeriod
            this.formData.finalPeriod = data.finalPeriod
            this.formData.budgetAccount = data.budgetAccount
            this.formData.budget = data.budget
            this.formData.objective = data.objective
            this.formData.description = data.description
            this.formData.actionsTotalCost = data.actionsTotalCost
            this.formData.initialPeriod = data.initialPeriod
            this.formData.finalPeriod = data.finalPeriod

            this.formData.actionCategory =
                _.get(this.actionData, "actionCategory.id") || null
            this.formData.goalCategory =
                _.get(this.actionData, "goalCategory.id") || null
            this.formData.responsible =
                _.get(this.actionData, "responsible.id") || null

            this.loading = false
        },
        async handleSubmit() {
            try {
                await Api.update("actions", {
                    id: this.actionData.id,
                    title: this.formData.title,
                    initialPeriod: this.formData.initialPeriod,
                    finalPeriod: this.formData.finalPeriod,
                    budgetAccount: this.formData.budgetAccount,
                    budget: parseFloat(this.formData.budget),
                    objective: this.formData.objective,
                    description: this.formData.description,
                    actionsTotalCost: null,
                    monthlyDonations: null,
                    recurringDonors: null,
                    actionCategory: this.formData.actionCategory
                        ? { id: this.formData.actionCategory }
                        : null,
                    goalCategory: this.formData.goalCategory
                        ? { id: this.formData.goalCategory }
                        : null,
                    responsible: this.formData.responsible
                        ? { id: this.formData.responsible }
                        : null,
                })

                this.$toast(toastMessages.getSuccessMessage())
            } catch (e) {
                this.$formulate.handle(e, "actionsForm")
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>
