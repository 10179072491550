<template>
    <b-card class="pb-0 mb-0 pr-0 pl-0">
        <div class="d-block">
            <feather-icon icon="UsersIcon" size="28" />
            <h4 class="d-inline">
                {{ $t("modules.actions.donationsAndResults") }}
            </h4>
            <feather-icon
                icon="ChevronDownIcon"
                size="28"
                class="float-right"
            />
        </div>
    </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue"
import Api from "@/services/api/index"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        BCard,
    },
    props: {
        actionData: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            data: {},
            selected: "",
            resourceEdit: null,
        }
    },
    methods: {
        showControls(index) {
            this.selected = index
        },
        hideControls() {
            this.selected = ""
        },

        async deletePerson(person) {
            const confirmDelete = await this.$bvModal.msgBoxConfirm(
                this.$t("common.crud.confirmDelete"),
                {
                    title: this.$t("common.crud.confirmPlease"),
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: this.$t("common.crud.yes"),
                    cancelTitle: this.$t("common.crud.no"),
                    cancelVariant: "primary",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                }
            )
            if (!confirmDelete) return
            try {
                await Api.one("actions", this.actionData.id)
                    .relationships("coordinators")
                    .destroy([
                        {
                            type: "people",
                            id: person.id,
                        },
                    ])

                this.$emit("deleteCoordinator", person)

                this.$toast(toastMessages.getSuccessMessage())
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>
