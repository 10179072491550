<template>
    <b-modal
        v-if="$can('update', 'actions')"
        v-bind="$attrs"
        ref="attach-administrator"
        centered
        :title="
            $tc('common.crud.attaching', 2) +
            ' ' +
            $tc('modules.people.description', 1).toLowerCase()
        "
        hideFooter
        @hide="setAttributesDefaultValues"
        @show="setAttributesDefaultValues"
    >
        <b-row>
            <b-col>
                <FormulateForm
                    v-slot="{ isLoading, hasErrors }"
                    v-model="formData"
                    name="actionAdministratorForm"
                    @submit="handleSubmit"
                >
                    <select-user
                        name="person"
                        :placeholder="
                            $t('common.crud.select') +
                            ' ' +
                            $tc('modules.people.description', 1).toLowerCase()
                        "
                        :label="$t('common.crud.name')"
                        validation="required"
                        :options="people"
                        @update:options="updateOptions($event)"
                        @update:input="formData.person = $event"
                    ></select-user>

                    <b-row class="mt-3">
                        <b-col class="d-flex justify-content-center">
                            <FormulateErrors />
                        </b-col>
                    </b-row>

                    <b-row class="d-flex justify-content-center">
                        <FormulateInput
                            :disabled="hasErrors || isLoading"
                            :label="
                                isLoading
                                    ? $tc('common.crud.attaching', 1)
                                    : $tc('common.crud.attaching', 2)
                            "
                            inputClass="btn btn-primary"
                            type="submit"
                            class="mr-2"
                        />
                        <FormulateInput
                            :label="$t('common.crud.close')"
                            inputClass="btn btn-danger"
                            type="button"
                            class="mr-5"
                            @click="hideModal"
                        />
                        <FormulateInput
                            v-if="people.length === 0 && peopleSearched"
                            :label="`+ ${$t('modules.people.new')}`"
                            inputClass="btn btn-success"
                            type="button"
                            class="mr-2"
                            @click="$eventHub.$emit('showCreatePeopleModal')"
                        />
                    </b-row>
                </FormulateForm>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from "bootstrap-vue"
import Api from "@/services/api/index"
import SelectUser from "@/views/components/utilis-spa/vue-select-async/SelectUser.vue"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        BModal,
        SelectUser,
        BRow,
        BCol,
    },
    props: {
        actionData: {
            required: true,
            type: Object,
        },
        resourceEdit: {
            required: false,
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            formData: {
                person: "",
            },
            people: [],
            peopleSearched: false,
        }
    },
    mounted() {
        this.$eventHub.$on("person:created", data => {
            this.people.unshift({ label: data.name, value: data.id })
            this.formData.person = data.id
        })
    },
    methods: {
        updateOptions(people) {
            this.people = people
            this.peopleSearched = true
        },
        setAttributesDefaultValues() {
            if (this.resourceEdit) {
                if (
                    !this.people.find(
                        option => option.value === this.resourceEdit.id
                    )
                )
                    this.people.unshift({
                        label: this.resourceEdit.name,
                        value: this.resourceEdit.id,
                    })
                this.formData.person = this.resourceEdit.id
            } else {
                this.people = []
                this.formData.person = ""
                this.peopleSearched = false
            }
        },
        async handleSubmit() {
            try {
                await Api.one("actions", this.actionData.id)
                    .relationships("administrators")
                    .post([
                        {
                            type: "people",
                            id: this.formData.person,
                        },
                    ])

                if (this.resourceEdit)
                    this.$emit("deleteAdministrator", this.resourceEdit)

                const selectedPerson = this.people.find(
                    option => option.value === this.formData.person
                )
                const person = {
                    id: selectedPerson.value,
                    name: selectedPerson.label,
                }
                this.$emit("attachAdministrator", person)

                this.$toast(toastMessages.getSuccessMessage())

                this.$refs["attach-administrator"].hide()
                this.$formulate.resetValidation("actionAdministratorForm")
            } catch (e) {
                this.$formulate.handle(e, "actionAdministratorForm")
                this.$toast(toastMessages.getUnexpectedError())
            }
        },

        showModal() {
            this.setAttributesDefaultValues()
            this.$refs["attach-administrator"].show()
        },
        hideModal() {
            this.$refs["attach-administrator"].hide()
        },
    },
}
</script>

<style scoped></style>
