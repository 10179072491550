<template>
    <b-card class="pb-0 mb-0 pr-0 pl-0">
        <div class="d-block">
            <feather-icon icon="UsersIcon" size="28" />
            <h4 class="d-inline">
                {{ $t("common.relationships") }}
            </h4>
            <feather-icon
                icon="ChevronDownIcon"
                size="28"
                class="float-right"
            />
        </div>

        <b-row class="mt-1">
            <b-col cols="12">
                <b-button class="relationship-buttons" disabled>
                    {{ $t("common.crud.volunteers") }}
                </b-button>
                <b-button class="relationship-buttons" disabled>
                    {{ $t("common.crud.donors") }}
                </b-button>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { BRow, BCard, BCol, BButton } from "bootstrap-vue"
import Api from "@/services/api/index"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        BRow,
        BCard,
        BCol,
        BButton,
    },
    props: {
        actionData: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            selected: "",
            resourceEdit: null,
        }
    },
    mounted() {},
    methods: {
        showControls(index) {
            this.selected = index
        },
        hideControls() {
            this.selected = ""
        },

        async deletePerson(person) {
            const confirmDelete = await this.$bvModal.msgBoxConfirm(
                this.$t("common.crud.confirmDelete"),
                {
                    title: this.$t("common.crud.confirmPlease"),
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: this.$t("common.crud.yes"),
                    cancelTitle: this.$t("common.crud.no"),
                    cancelVariant: "primary",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                }
            )
            if (!confirmDelete) return
            try {
                await Api.one("actions", this.actionData.id)
                    .relationships("coordinators")
                    .destroy([
                        {
                            type: "people",
                            id: person.id,
                        },
                    ])

                this.$emit("deleteCoordinator", person)

                this.$toast(toastMessages.getSuccessMessage())
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>

<style scoped>
.relationship-buttons {
    background-color: white !important;
    border-style: groove;
    border-width: 0.5rem;
    color: #5e5873 !important;
    border-color: #e3e3e352 !important;
    margin-right: 1rem;
}
</style>
