var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$can('update', 'actions'))?_c('b-modal',_vm._b({ref:"attach-administrator",attrs:{"centered":"","title":_vm.$tc('common.crud.attaching', 2) +
        ' ' +
        _vm.$tc('modules.people.description', 1).toLowerCase(),"hideFooter":""},on:{"hide":_vm.setAttributesDefaultValues,"show":_vm.setAttributesDefaultValues}},'b-modal',_vm.$attrs,false),[_c('b-row',[_c('b-col',[_c('FormulateForm',{attrs:{"name":"actionAdministratorForm"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var isLoading = ref.isLoading;
        var hasErrors = ref.hasErrors;
return [_c('select-user',{attrs:{"name":"person","placeholder":_vm.$t('common.crud.select') +
                        ' ' +
                        _vm.$tc('modules.people.description', 1).toLowerCase(),"label":_vm.$t('common.crud.name'),"validation":"required","options":_vm.people},on:{"update:options":function($event){return _vm.updateOptions($event)},"update:input":function($event){_vm.formData.person = $event}}}),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('FormulateErrors')],1)],1),_c('b-row',{staticClass:"d-flex justify-content-center"},[_c('FormulateInput',{staticClass:"mr-2",attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                                ? _vm.$tc('common.crud.attaching', 1)
                                : _vm.$tc('common.crud.attaching', 2),"inputClass":"btn btn-primary","type":"submit"}}),_c('FormulateInput',{staticClass:"mr-5",attrs:{"label":_vm.$t('common.crud.close'),"inputClass":"btn btn-danger","type":"button"},on:{"click":_vm.hideModal}}),(_vm.people.length === 0 && _vm.peopleSearched)?_c('FormulateInput',{staticClass:"mr-2",attrs:{"label":("+ " + (_vm.$t('modules.people.new'))),"inputClass":"btn btn-success","type":"button"},on:{"click":function($event){return _vm.$eventHub.$emit('showCreatePeopleModal')}}}):_vm._e()],1)]}}],null,false,3815886913),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }