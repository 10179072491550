<template>
    <div class="component">
        <b-card>
            <b-row>
                <b-col cols="12" md="4">
                    <b-row>
                        <b-col cols="6" md="3" class="mr-2">
                            <b-avatar
                                variant="info"
                                :text="getTitleInitials"
                                size="10rem"
                            ></b-avatar>
                        </b-col>

                        <b-col class="mr-2" cols="6" md="8">
                            <b-row>
                                <h4
                                    v-if="actionData.title"
                                    class="text-truncate d-block"
                                >
                                    {{ actionData.title }}
                                </h4>
                            </b-row>

                            <b-row class="mt-3">
                                <b-col>
                                    <b-row>
                                        <p class="font-medium-2">
                                            {{
                                                $t("modules.people.profile") +
                                                " " +
                                                $t("common.status")
                                            }}
                                        </p>
                                    </b-row>

                                    <b-row class="">
                                        <b-badge
                                            v-if="actionData.status"
                                            :variant="
                                                statusVariant(
                                                    actionData.status
                                                        .description
                                                )
                                            "
                                            class="mr-1"
                                            style="height: 1.5rem"
                                        >
                                            {{ actionData.status.description }}
                                        </b-badge>

                                        <p
                                            v-if="actionData.id"
                                            class="text-truncate"
                                        >
                                            ID: {{ actionData.id }}
                                        </p>
                                    </b-row>

                                    <b-row>
                                        <p v-if="actionData.editor">
                                            {{
                                                $t(
                                                    "common.crud.lastUpdatedAtBy",
                                                    {
                                                        time: $d(
                                                            new Date(
                                                                actionData.updatedAt
                                                            ),
                                                            "dateTime"
                                                        ),
                                                        user:
                                                            actionData.editor
                                                                .name,
                                                    }
                                                )
                                            }}
                                        </p>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row class="mt-4">
                        <b-col>
                            <b-button
                                v-if="$can('edit actions')"
                                class="mr-1"
                                variant="primary"
                                style="background-color: #519bd6"
                                @click="
                                    $emit(
                                        'openEditResourceTab',
                                        'companiesEditTabForm'
                                    )
                                "
                            >
                                {{
                                    $t("common.crud.editResource", {
                                        resource: $tc(
                                            "modules.actions.description",
                                            1
                                        ).toLowerCase(),
                                    })
                                }}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCol,
    BRow,
    VBToggle,
} from "bootstrap-vue"
import _ from "lodash"

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BAvatar,
        BBadge,
        BButton,
    },
    directives: {
        "b-toggle": VBToggle,
    },
    props: {
        actionData: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {
            collapseVisible: false,
            formData: {
                relationships: [],
                classifications: [],
            },
            enableRequest: false,
        }
    },
    computed: {
        getTitleInitials() {
            const split = this.actionData.title.split(" ", 2)
            if (split.length < 2) {
                return this.actionData.title[0] + this.actionData.title[1]
            }
            const first = split[0]
            const second = split[1]
            return first.charAt(0) + second.charAt(0)
        },

        statusVariant() {
            const statusColor = {
                Ativo: "light-success",
                Inativo: "light-danger",
            }

            return status => statusColor[status]
        },
    },
    mounted() {
        this.enableRequest = true
    },
    created() {
        this.formData.classifications =
            (_.get(this.actionData, "classifications") &&
                this.actionData.classifications.map(el =>
                    el.id.toString()
                )) ||
            []

        this.formData.relationships =
            (_.get(this.actionData, "relations") &&
                this.actionData.relations.map(el => el.id.toString())) ||
            []
    },
    methods: {
        changeRoute(actionId) {
            this.$router.push({
                name: "edit-actions",
                params: { id: actionId },
            })
        },
    },
}
</script>

<style scoped></style>
