var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('formulateForm',{attrs:{"name":"actionsForm"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var hasErrors = ref.hasErrors;
return [_c('b-card',{staticClass:"mt-2"},[_c('div',{staticClass:"mb-2"},[_c('span',{staticClass:"section-header"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"14"}}),_vm._v(" "+_vm._s(_vm.$t("modules.people.profile"))+" ")],1)]),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('formulateInput',{attrs:{"label":_vm.$tc('modules.actions.description', 1),"name":"title","placeholder":_vm.$t('modules.actions.type'),"type":"text","validation":"required|max:255,length"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('formulateInput',{attrs:{"label":_vm.$t('modules.actions.type'),"name":"actionCategory","placeholder":_vm.$t('common.crud.select') +
                            ' ' +
                            _vm.$t(
                                'modules.actions.actionCategory'
                            ).toLowerCase(),"type":"vue-select","validation":"optional","options":_vm.actionsCategoriesOptions.map(function (el) { return ({
                                value: el.id,
                                label: el.description,
                            }); })},on:{"update:input":function($event){_vm.formData.actionCategory = $event}}})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('formulate-input',{attrs:{"label":_vm.$t('modules.actions.initialPeriod'),"name":"initialPeriod","type":"custom-date","placeholder":_vm.$t('customFormats.date'),"validation":"optional|date:YYYY-MM-DD"},on:{"update:date":function($event){_vm.formData.initialPeriod = $event}},model:{value:(_vm.formData.initialPeriod),callback:function ($$v) {_vm.$set(_vm.formData, "initialPeriod", $$v)},expression:"formData.initialPeriod"}})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('formulate-input',{attrs:{"label":_vm.$t('modules.actions.finalPeriod'),"name":"finalPeriod","type":"custom-date","validation":"optional|date:YYYY-MM-DD","placeholder":_vm.$t('customFormats.date')},on:{"update:date":function($event){_vm.formData.finalPeriod = $event}},model:{value:(_vm.formData.finalPeriod),callback:function ($$v) {_vm.$set(_vm.formData, "finalPeriod", $$v)},expression:"formData.finalPeriod"}})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('formulateInput',{attrs:{"label":_vm.$t('modules.actions.budgetAccount'),"name":"budgetAccount","placeholder":"0","type":"text","validation":"optional|max:255,length"}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('formulateInput',{attrs:{"label":_vm.$t('modules.actions.budget'),"name":"budget","placeholder":_vm.$n(0.0, 'currency'),"type":"text","validation":"optional|number"}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('select-user',{attrs:{"name":"responsible","placeholder":_vm.$t('common.crud.select') +
                            ' ' +
                            _vm.$tc(
                                'modules.people.description',
                                1
                            ).toLowerCase(),"label":_vm.$t('modules.actions.responsible'),"validation":"optional","options":_vm.responsibleOptions},on:{"update:options":function($event){_vm.responsibleOptions = $event},"update:input":function($event){_vm.formData.responsible = $event}}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('formulateInput',{attrs:{"label":_vm.$t('modules.actions.goalCategory'),"name":"goalCategory","placeholder":_vm.$t('common.crud.select') +
                            ' ' +
                            _vm.$t('modules.actions.goalCategory').toLowerCase(),"type":"vue-select","validation":"optional","options":_vm.goalCategoriesOptions.map(function (el) { return ({
                                value: el.id,
                                label: el.description,
                            }); })},on:{"update:input":function($event){_vm.formData.goalCategory = $event}}})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('formulateInput',{attrs:{"label":_vm.$t('modules.actions.objective'),"name":"objective","placeholder":_vm.$t('common.crud.informAttribute', {
                                attribute: _vm.$t(
                                    'modules.actions.actionObjective'
                                ).toLowerCase(),
                            }),"type":"textarea","validation":"optional|max:255,length"}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('formulateInput',{attrs:{"label":_vm.$t('common.crud.description'),"name":"description","placeholder":_vm.$t('common.crud.informAttribute', {
                                attribute: _vm.$tc(
                                    'modules.actions.actionDescription',
                                    1
                                ).toLowerCase(),
                            }),"type":"textarea","validation":"optional|max:255,length"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('formulateErrors')],1)],1),_c('b-row',{staticClass:"justify-content-center align-self-center mt-2 ml-0"},[_c('formulateInput',{staticClass:"pr-2",attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                            ? _vm.$t('common.crud.updating')
                            : _vm.$t('common.crud.update'),"inputClass":"btn btn-primary","type":"submit"}}),_c('formulateInput',{staticClass:"pl-2",attrs:{"label":_vm.$t('common.crud.close'),"inputClass":"btn btn-danger","type":"button"},on:{"click":function($event){return _vm.$emit('closeEditForm')}}})],1)],1)]}}]),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }