var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"pb-0 mb-0 pr-0 pl-0"},[(_vm.actionData)?_c('attach-coordinators-modal',_vm._g({attrs:{"id":"add-coordinator","actionData":_vm.actionData,"resourceEdit":_vm.resourceEdit}},_vm.$listeners)):_vm._e(),_c('div',{staticClass:"d-block"},[_c('feather-icon',{attrs:{"icon":"UsersIcon","size":"28"}}),_c('h4',{staticClass:"d-inline"},[_vm._v(" "+_vm._s(_vm.$t("modules.actions.coordination"))+" ")]),_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.add-coordinator",modifiers:{"add-coordinator":true}}],staticClass:"pl-2 a-link",on:{"click":function($event){_vm.resourceEdit = null}}},[_c('p',{staticClass:"under-blue-text d-inline"},[_vm._v(" + "+_vm._s(_vm.$tc("common.crud.attaching", 2))+" ")])]),_c('feather-icon',{staticClass:"float-right",attrs:{"icon":"ChevronDownIcon","size":"28"}})],1),(_vm.actionData)?_c('b-row',{staticClass:"mt-1 ml-0"},_vm._l((_vm.actionData.coordinators),function(person,index){return _c('div',{key:("person-index-" + index),staticClass:"w-100 mt-1",on:{"mouseover":function($event){return _vm.showControls(index)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('span',[_vm._v(" "+_vm._s(person.name)+" ")])]),_c('b-col',{attrs:{"cols":"12","md":"4"}},[(
                            person.hasOwnProperty('phones') &&
                            person.phones.length > 0
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.maskedPhoneNumber(person.phones[0].number))+" ")]):_vm._e()]),_c('b-col',{attrs:{"cols":"4","md":"3"}},[(
                            _vm.selected === index && _vm.$can('update', 'actions')
                        )?_c('feather-icon',{staticClass:"float-right under-blue-text d-inline align-middle",staticStyle:{"margin-right":"0.5rem"},attrs:{"icon":"Trash2Icon","size":"15"},on:{"click":function($event){return _vm.deletePerson(person)}}}):_vm._e(),(
                            _vm.selected === index && _vm.$can('update', 'actions')
                        )?_c('feather-icon',{staticClass:"float-right under-blue-text d-inline align-middle mr-1",attrs:{"icon":"Edit2Icon","size":"15"},on:{"click":function($event){return _vm.$router.push({
                                name: 'view-people',
                                params: { id: person.id },
                            })}}}):_vm._e()],1)],1)],1)}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }