<template>
    <b-card class="pb-0 mb-0 pr-0 pl-0">
        <attach-coordinators-modal
            v-if="actionData"
            id="add-coordinator"
            :actionData="actionData"
            :resourceEdit="resourceEdit"
            v-on="$listeners"
        >
        </attach-coordinators-modal>

        <div class="d-block">
            <feather-icon icon="UsersIcon" size="28" />
            <h4 class="d-inline">
                {{ $t("modules.actions.coordination") }}
            </h4>
            <a
                v-b-modal.add-coordinator
                class="pl-2 a-link"
                @click="resourceEdit = null"
            >
                <p class="under-blue-text d-inline">
                    +
                    {{ $tc("common.crud.attaching", 2) }}
                </p>
            </a>
            <feather-icon
                icon="ChevronDownIcon"
                size="28"
                class="float-right"
            />
        </div>

        <b-row v-if="actionData" class="mt-1 ml-0">
            <div
                v-for="(person, index) in actionData.coordinators"
                :key="`person-index-${index}`"
                class="w-100 mt-1"
                @mouseover="showControls(index)"
            >
                <b-row>
                    <b-col cols="12" md="5">
                        <span>
                            {{ person.name }}
                        </span>
                    </b-col>
                    <b-col cols="12" md="4">
                        <span
                            v-if="
                                person.hasOwnProperty('phones') &&
                                person.phones.length > 0
                            "
                        >
                            {{ maskedPhoneNumber(person.phones[0].number) }}
                        </span>
                    </b-col>
                    <b-col cols="4" md="3">
                        <feather-icon
                            v-if="
                                selected === index && $can('update', 'actions')
                            "
                            icon="Trash2Icon"
                            size="15"
                            class="float-right under-blue-text d-inline align-middle"
                            style="margin-right: 0.5rem"
                            @click="deletePerson(person)"
                        ></feather-icon>
                        <feather-icon
                            v-if="
                                selected === index && $can('update', 'actions')
                            "
                            icon="Edit2Icon"
                            size="15"
                            class="float-right under-blue-text d-inline align-middle mr-1"
                            @click="
                                $router.push({
                                    name: 'view-people',
                                    params: { id: person.id },
                                })
                            "
                        ></feather-icon>
                    </b-col>
                </b-row>
            </div>
        </b-row>
    </b-card>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue"
import Api from "@/services/api/index"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import AttachCoordinatorsModal from "./AttachCoordinatorsModal.vue"

export default {
    components: {
        BRow,
        BCol,
        BCard,
        AttachCoordinatorsModal,
    },
    props: {
        actionData: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            selected: "",
            resourceEdit: null,
        }
    },
    mounted() {},
    methods: {
        showControls(index) {
            this.selected = index
        },
        hideControls() {
            this.selected = ""
        },

        async deletePerson(person) {
            const confirmDelete = await this.$bvModal.msgBoxConfirm(
                this.$t("common.crud.confirmDelete"),
                {
                    title: this.$t("common.crud.confirmPlease"),
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: this.$t("common.crud.yes"),
                    cancelTitle: this.$t("common.crud.no"),
                    cancelVariant: "primary",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                }
            )
            if (!confirmDelete) return
            try {
                await Api.one("actions", this.actionData.id)
                    .relationships("coordinators")
                    .destroy([
                        {
                            type: "people",
                            id: person.id,
                        },
                    ])

                this.$emit("deleteCoordinator", person)

                this.$toast(toastMessages.getSuccessMessage())
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>
