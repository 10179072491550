<template>
    <div>
        <div v-if="loading" class="text-center">
            <b-spinner variant="primary" class="" />
        </div>
        <div v-else>
            <!-- Alert: No item found -->
            <b-alert variant="danger" :show="actionData === undefined">
                <h4 class="alert-heading">
                    {{
                        $t("common.crud.errors.fetch", {
                            resource: $tc("modules.actions.description", 1),
                        })
                    }}
                </h4>
                <div class="alert-body">
                    {{
                        $t("common.crud.errors.notfound", {
                            resource: $tc("modules.actions.description", 1),
                        })
                    }}
                    <b-link class="alert-link" :to="{ name: 'actions' }">
                        {{
                            $t("common.crud.errors.list", {
                                resource: $tc("modules.actions.description", 1),
                            })
                        }}
                    </b-link>
                    {{
                        $t("common.crud.errors.resource", {
                            resource: $tc("modules.actions.description", 2),
                        })
                    }}
                </div>
            </b-alert>

            <b-row>
                <b-col cols="12" md="12">
                    <actions-view-profile-image
                        v-if="actionData"
                        :actionData="actionData"
                        @openEditResourceTab="activeTab = 4"
                    >
                    </actions-view-profile-image>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" md="3">
                    <action-relationships-card :actionData="actionData">
                    </action-relationships-card>
                    <action-administrators-card
                        v-if="actionData"
                        :actionData="actionData"
                        class="mt-2"
                        @deleteAdministrator="
                            actionData.administrators = actionData.administrators.filter(
                                (el) => el.id !== $event.id
                            )
                        "
                        @attachAdministrator="attachAdministrator($event)"
                    >
                    </action-administrators-card>

                    <action-coordinators-card
                        v-if="actionData"
                        :actionData="actionData"
                        class="mt-2"
                        @deleteCoordinator="
                            actionData.coordinators = actionData.coordinators.filter(
                                (el) => el.id !== $event.id
                            )
                        "
                        @attachCoordinator="attachCoordinator($event)"
                    >
                    </action-coordinators-card>

                    <donations-and-results-card
                        :actionData="actionData"
                        class="mt-2"
                    >
                    </donations-and-results-card>
                </b-col>

                <b-col cols="12" md="9">
                    <b-tabs
                        v-model="activeTab"
                        pills
                        navClass="nav-pill-primary"
                        navWrapperClass="submenu"
                        class="pill-component"
                    >
                        <b-tab>
                            <template #title>
                                <feather-icon
                                    icon="PieChartIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $t("common.crud.general") }}
                                </span>
                            </template>
                            <b-tabs
                                pills
                                navClass="nav-pill-primary"
                                navWrapperClass="submenu"
                                class="pill-component"
                            >
                                <b-tab :title="$t('common.tasks')" disabled>
                                    <!--                                    <TasksList-->
                                    <!--                                        v-if="actionData"-->
                                    <!--                                        :resourceData="actionData"-->
                                    <!--                                        resourceType="actions"-->
                                    <!--                                    >-->
                                    <!--                                    </TasksList>-->
                                </b-tab>
                                <b-tab :title="$t('common.notes')" active>
                                    <NotesList
                                        v-if="actionData"
                                        :resourceData="actionData"
                                        resourceType="actions"
                                    >
                                    </NotesList>
                                </b-tab>
                                <b-tab
                                    :title="$t('common.crud.attachments')"
                                    disabled
                                >
                                    <b-card
                                        :title="$t('common.crud.attachments')"
                                    ></b-card>
                                </b-tab>
                                <b-tab
                                    :title="$t('common.crud.operations')"
                                    disabled
                                >
                                    <b-card
                                        :title="$t('common.crud.operations')"
                                    ></b-card>
                                </b-tab>
                            </b-tabs>
                        </b-tab>

                        <b-tab class="pr-1" disabled>
                            <template #title>
                                <feather-icon
                                    icon="DollarSignIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $t("common.crud.financialDonations") }}
                                </span>
                            </template>
                        </b-tab>

                        <b-tab disabled>
                            <template #title>
                                <feather-icon
                                    icon="BookOpenIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem" disabled>
                                    {{ $t("common.crud.materialDonations") }}
                                </span>
                            </template>
                        </b-tab>

                        <b-tab disabled>
                            <template #title>
                                <feather-icon
                                    icon="ListIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $t("common.crud.recurrenceList") }}
                                </span>
                            </template>
                        </b-tab>

                        <b-tab
                            v-if="$can('edit actions')"
                            card
                            :titleItemClass="activeTab !== 4 ? 'd-none' : ''"
                        >
                            <template #title>
                                <feather-icon
                                    icon="UserIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $t("modules.people.profile") }}
                                </span>
                            </template>
                            <action-edit
                                v-if="actionData"
                                :actionData="actionData"
                                :actionsCategoriesOptions="
                                    actionsCategoriesOptions
                                "
                                :goalCategoriesOptions="goalCategoriesOptions"
                                @closeEditForm="activeTab = 1"
                                @updateaction="updateaction($event)"
                            >
                            </action-edit>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import {
    BAlert,
    BLink,
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
    BSpinner,
} from "bootstrap-vue"
import router from "@/router"
import ActionsViewProfileImage from "@/views/app/actions/ActionsViewProfileImage.vue"
import Api from "@/services/api"
import NotesList from "@/views/app/notes/notes-list/NotesList.vue"
import _ from "lodash"
import ActionAdministratorsCard from "./ActionAdministratorsCard.vue"
import ActionCoordinatorsCard from "./ActionCoordinatorsCard.vue"
import ActionRelationshipsCard from "./ActionRelationshipsCard.vue"
import DonationsAndResultsCard from "./DonationsAndResultsCard.vue"
import ActionEdit from "./ActionsEdit.vue"

export default {
    components: {
        ActionEdit,
        ActionsViewProfileImage,
        BAlert,
        BLink,
        BCard,
        ActionAdministratorsCard,
        ActionCoordinatorsCard,
        DonationsAndResultsCard,
        ActionRelationshipsCard,
        NotesList,
        BRow,
        BCol,
        BTab,
        BTabs,
        BSpinner,
    },

    data() {
        return {
            actionsCategoriesOptions: [],
            goalCategoriesOptions: [],
            actionData: null,
            loading: true,
            activeTab: 0,
        }
    },
    watch: {
        $route(to, from) {
            // Watching route to reload resource data when redirecting to the same route name
            if (to.name === from.name) {
                this.getPersonData(to.params.id)
            }
        },
    },
    async mounted() {
        const requests = await Promise.all([
            this.getActionCategory(),
            this.getGoalCategories(),
            this.getActionData(router.currentRoute.params.id),
        ])

        this.actionsCategoriesOptions = requests[0].data
        this.goalCategoriesOptions = requests[1].data
        this.actionData = requests[2].data
        this.loading = false
    },
    methods: {
        async attachAdministrator(person) {
            const action = _.cloneDeep(this.actionData)
            action.administrators = [...action.administrators, ...[person]]

            this.actionData = action
        },
        async attachCoordinator(person) {
            const action = _.cloneDeep(this.actionData)
            action.coordinators = [...action.coordinators, ...[person]]

            this.actionData = action
        },
        getActionData(id) {
            return Api.find("actions", id, {
                include:
                    "notes,notes.creator,responsible,actionCategory,goalCategory,creator,editor,destroyer," +
                    "administrators,coordinators,administrators.phones,coordinators.phones",
                fields: {
                    notes: "description,createdAt,updatedAt,creator,editor",
                    people: "name,phones",
                },
            })
        },
        getAdministrators(id) {
            return Api.one("actions", id).all("administrators").get()
        },
        getCoordinators(id) {
            return Api.one("actions", id).all("coordinators").get()
        },
        async updateAction(id) {
            const response = await this.getActionData(id)
            this.actionData = response.data
        },

        getActionCategory() {
            return Api.findAll("action-categories")
        },

        getGoalCategories() {
            return Api.findAll("goal-categories", {
                fields: { "goal-categories": "description" },
            })
        },
        getPhoneClassifications() {
            return Api.findAll("phone-classifications", {
                fields: { "phone-classifications": "description" },
            })
        },
    },
}
</script>

<style scoped lang="scss">
.profile-menu {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    border-radius: 5px;
    color: #6e6b7b !important;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.4px;
}

.profile-menu-selected {
    background-color: #519bd6 !important;
    border-color: #519bd6 !important;
    border-radius: 5px;
    color: #ffffff !important;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.4px;
}

.nav-tabs .nav-item .nav-link {
    font-size: 1rem;
    line-height: 1rem;
    color: #5e5873;
}

.pill-component {
    width: 99%;
}
</style>
